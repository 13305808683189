.App {
  text-align: center;
  box-sizing: border-box;
  position: relative;
}

body {
  font-family: 'Inter Tight', sans-serif;
  font-weight: 400; /* Regular text */
}

b, strong {
  font-weight: 600; /* Bold text */
}

.particles {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  /* height: 100%;
  width: 100%; */
  top: 0;
  left: 0;
}
