.header-row {
  color: black;
   /* rgb(197, 69, 63); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.header-text {
  font-family: 'Hedvig Letters Serif', serif;
  top: 0;
  left: 0;
  letter-spacing: 1px;
  padding: 0;
  margin-top: 0;
  backdrop-filter: blur(1px);
  border-radius: 10%;
  color: black ;
  /* // rgb(197, 69, 63); */
  font-size: 4rem;
  /* background-color: #1B4F72; */
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: left;

  margin-left: 0;
  margin-bottom: 0;

}

.header-icon-container{
  display: flex;
    backdrop-filter: blur(1px);
}

.header-icon {
  height: 4rem;
  width: 4rem;
  padding: 1rem;
  color:  black;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.3));
}

a {
    color: black;
    text-decoration: none; /* optional: removes the underline from links */
}

@media (max-width: 768px) {
  .header-text {
    font-size: 2rem;
  }
  .header-icon {
    height: 2rem;
    width: 2rem;
    padding: 0.25rem;
  }
  .header-row {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
}
