


.skills {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
}

.skill {
    flex: 1;
    margin-left: 1rem;
    margin-right: 1rem;
}