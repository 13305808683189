
.timeline-nodes{
    display: flex;
    flex-direction: column;
}

.timeline-node {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.timeline-content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.timeline-content {
    margin-left: 0.5rem;
    height: 6.8rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.spacing {
    width: 6rem;
    height: 100%
}

.timeline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem;
}

.timeline-node-circle {
    display: flex;
    border-radius: 5rem;
    background-color: white;
    border: 0.4rem solid black;
}

.logo {

    height: 5rem;
    width: 5rem;
    padding: 0.5rem;

    object-fit: contain;
}

.timeline-content-top {
    margin: 0;
    font-size: 1.2rem; /* Add this line to set the font size */
}

@media (max-width: 768px) {
    .timeline-content-top {
        margin: 0;
        font-size: 1.2rem; /* Add this line to set the font size */
    }
}



.timeline-content-bottom {
    margin: 0;

}

.line{
    width: 1rem;
    height: 2rem;
    background-color: black;
    padding-left: 0;
    margin-top: -0.1rem;
    margin-bottom: -0.1rem;
}

.space {
    width: 1rem;
    height: 2rem;
    padding-left: 0;
    margin-top: -0.1rem;
    margin-bottom: -0.1rem;
}