.section-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1rem;
  align-items: center;
  font-family: 'Hedvig Letters Serif', serif;
}

.icon {
  padding: 1rem;
  width: 2rem;
  height: 2rem;
}