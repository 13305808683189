
.projects{
     margin: auto;

    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
}


.project{
    width: 100%;
    display: flex;
    flex-direction: column;

}

.project-title{
    margin-top: 1rem;
    margin-bottom: 1rem;;
}

.project-description{
    margin-top: 0;
    margin-bottom: 1rem;;
}

.project-photos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.project-photo {
    object-fit: contain;
    height: auto;
    flex: 1;
}