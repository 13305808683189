.card {
  flex-direction: column;
  margin: auto;
  /* margin-bottom: 1rem; */
  text-align: center;
  display: flex;
background: #F5F5F5;
border-radius: 5rem 0rem 5rem 0rem;
  width: 100%;
  margin-bottom: 2rem;


  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}